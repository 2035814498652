import Navbar from '../externals/Navbar';
import { useState, useEffect, useRef } from "react";
import axios from "axios";

function LiveResults()
{
    const [competitorData, setCompetitorData] = useState();
    //const [teamsData, setTeamsData] = useState();

    const pageRef = useRef(null);
    const [isAtBottom, setBottom] = useState(false)
    //const [getTeams, setGetTeams] = useState(false)

useEffect(() => {

  axios.get('https://nodeppcbackend-production.up.railway.app/api/liveResults', {
            params : {
                Stage:"etapa4"
              
            }})
            .then((response) => {
            
              setCompetitorData(response.data)

              //setGetTeams(true)
           })
           .catch((error) => {});
   
 
}, [])

/*useEffect(() => {

  if(getTeams === true){
    axios.get('https://nodeppcbackend-production.up.railway.app/api/liveResultsTeams', {
     params : {
         Stage:"ce2024"
       
     }})
     .then((response) => {
     
       setTeamsData(response.data)
       
    })
    .catch((error) => {});
  }
}, [getTeams])*/


useEffect(() => {
  
  console.log(window.innerHeight)
  const page = pageRef.current;

  if (!page) return;

 
  let scrollInterval;

  // Start auto-scrolling
  const startAutoScroll = () => {
    
    scrollInterval = setInterval(() => {
      if (isAtBottom === false) {
        window.scrollBy( 0,2)
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const scrollBottom = windowHeight + scrollTop >= documentHeight;
        setBottom(scrollBottom);
        //console.log(scrollBottom);
      } else {
     
        clearInterval(scrollInterval)
      }
    }, 30); // Adjust the interval as needed
  };

  startAutoScroll();

  // Stop auto-scrolling when component unmounts
  return () => clearInterval(scrollInterval);
}, []);

useEffect(() => {
  let prepareRefresh;

  if(isAtBottom === true){
   prepareRefresh = setInterval(()=>{
      clearInterval(prepareRefresh)
      window.location.reload(false);

  },5000)

  return () => clearInterval(prepareRefresh);
}
 
}, [isAtBottom]);


const competitors = competitorData?.map((data,index) =>{
  //console.log(data)
  if(data.WAID === "NA")
  {
      data.WAID = " "
  }

      return(
      <tr>

          <td>{data.WAID}</td>
          <td>{data.LastName}</td>
          <td>{data.FirstName}</td>
          <td>{data.REG}</td>
          <td>{data.Trial}</td>
          <td>{data.Classification}</td>
          <td>{data.TotalX}</td>
          <td>{data.Points}</td>

      </tr>
      );
  
})

/*const teams = teamsData?.map((data,index) =>{
  //console.log(data)
  if(data.WAID === "NA")
  {
      data.WAID = " "
  }
    
  if(index % 2 !== 0)
  {
      return(
      <>
      <tr>

          <td>{data.WAID}</td>
          <td>{data.LastName}</td>
          <td>{data.FirstName}</td>
          <td>{data.REG}</td>
          <td>{data.Trial}</td>
          <td>{data.Classification}</td>
          <td> </td>
          <td> </td>

      </tr>
      <tr>

          <td> </td>
          <td> </td>
          <td> </td>
          <td> </td>
          <td> </td>
          <td> </td>
          <td> </td>
          <td> </td>

      </tr>
      </>

      );
  }
  else
  {
    return(
  
      <tr>

          <td>{data.WAID}</td>
          <td>{data.LastName}</td>
          <td>{data.FirstName}</td>
          <td>{data.REG}</td>
          <td>{data.Trial}</td>
          <td>{data.Classification}</td>
          <td>{data.TotalX}</td>
          <td>{data.Points}</td>

      </tr>
      
    

      );
  }
  
})*/
    return(
        <div ref={pageRef}>
          <Navbar />
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          {<div className='container bg-warning'>
            <table className="table table-borderless text-center" style={{ height: '800px', overflowY: 'scroll' }}>
            <thead>
                <tr className="table-warning">
                    <th scope="col">WA1500</th>
                    <th scope="col">Nume</th>
                    <th scope="col">Prenume</th>
                    <th scope="col">REG</th>
                    <th scope="col">Proba</th>
                    <th scope="col">Clasificare</th>
                    <th scope="col">Total X</th>
                    <th scope="col">Punctaj</th>           
                </tr>
            </thead>
            <tbody className="table-secondary">
                {competitors}
                
            </tbody>
        </table>
    </div> }
        </div>
      
      

      
    )
}

export default LiveResults