function Footer(){
    return(
        <section id="Footer" className="p-3 mb-2 bg-dark text-light"  style={{
            position: "relative",
            bottom: "0vh",
            width: "100%",
            textAlign: "left"
        }}>
            <br></br>
            
             <h5>Contact Us</h5>
             <br></br>
             <p className="text-left"><i class="bi bi-envelope"></i> ppcromaniaro@gmail.com</p>
             <p className="text-left"><i class="bi bi-geo-alt-fill"></i> Intrarea Poligonului, Nr.1, Joița 087150</p>
            
           
            <br></br><br></br><br></br>
        </section>
    )
}

export default Footer;