import Navbar from '../externals/Navbar';
import Hero from '../externals/Hero';
import Footer from '../externals/Footer';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useNavigate } from 'react-router';

function Results(){

  const navigate = useNavigate();
  function showRequestedResults(event){

    switch(event.target.id)
    {
        case "r2024":
            navigate("/Results/2024")
            break;
        
        case "r2025":
            navigate("/Results/2025")
            break;
            
        default:
            break;
    }

}

/*function showLiveResults()
{
    navigate('/Live');
}

function goToResultSearch()
{
    navigate('/Search Results');
}*/



  return(
    <div> 
        <Navbar />
        <Hero />
        <br></br>
        <br></br> 
            <div className='container text-center'>
            <div className="card shadow-lg text-left">
                      <div className="card-body">
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='r2024' onClick={showRequestedResults}>Rezultate 2024</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='r2025' onClick={showRequestedResults}>Rezultate 2025</button>
                            <br></br>
                            <br></br>
                           
                      </div>
              </div>
            </div>
      
        <br></br>   
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>   
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>   
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>   
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>   
        <br></br>
       

        <Footer />
    </div>
    

);

}

export default Results;